<template>
  <div class="border-card-player p-8">
    <div class="mb-10">
      <h1 class="text-2xl font-bold tracking-tighter uppercase">
        {{ formPlayer.name }}
      </h1>
    </div>
    <main>
      <form @submit.prevent="handleSubmitInfoPlayer">
        <div class="form__div">
          <input
            class="form__input"
            type="date"
            id="date_birth"
            placeholder=" "
            v-model="formPlayer.date"
          />
          <label for="first_name" class="form__label">Date of Birth</label>
        </div>
        <div class="w-full form__div">
          <input
            class="form__input"
            type="text"
            id="first_name"
            placeholder=" "
            v-model="formPlayer.name"
          />
          <label for="first_name" class="form__label">Username</label>
        </div>
        <div class="form__div">
        <input 
          :id="`phone_number_${player.id}`"
          class="form__input" 
          type="text" 
          placeholder=" " 
          v-model="formPlayer.phoneNumber" 
        />
          <label :for="`phone_number_${player.id}`" class="form__label">Phone number (optional)</label>
        </div>
        <div class="flex items-center mb-4">
          <input class="ml-1" type="checkbox" v-model="formPlayer.acceptedOptIn" />
          <span class="ml-2">
            Opt In for Mastermind text messaging for important training updates
          </span>
        </div>
        <Alert :alert="alert" />
        <div class="flex items-center justify-end my-3 mt-6">
          <button
            class="text-color submit-btn font-bold px-4 py-1"
            type="submit"
          >
            Save Changes
          </button>
        </div>
      </form>
    </main>
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
import { editPlayer } from "@/services/user/user";
import Alert from "@/components/general/Alert";
import { formatPhoneNumber } from "@/utils/formatPhoneNumber.js";
import { calculateAge } from "@/utils/calculateAge.js";

const alert = ref({ message: "", active: false, error: false });
const props = defineProps(["player"]);
const formPlayer = ref({
  date: props.player.date_birth,
  name: props.player.username,
  phoneNumber: props.player.phone_number.replace(/^\+1/, ''),
  acceptedOptIn: props.player.opt_in_status,
  id: props.player.id,
});

async function handleSubmitInfoPlayer() {
  try {
    const body = {
      username: formPlayer.value.name.trim(),
      date_birth: formPlayer.value.date,
      profile: {
        phone_number: formPlayer.value.phoneNumber ? `+1${formPlayer.value.phoneNumber}` : "",
        opt_in_status: formPlayer.value.acceptedOptIn
      }
    };
    await editPlayer(formPlayer.value.id, body);
    handleAlert(`User ${formPlayer.value.name} has been updated!`, false);
  } catch (error) {
    handleAlert(error.message, true);
  }
}

function handleAlert(message, error) {
  alert.value = { message, active: true, error };
  setTimeout(() => {
    alert.value.active = false;
  }, 3000);
}

watch(() => formPlayer.value.date, (newValue, oldValue) => {
  if (newValue) {
    const age = calculateAge(newValue);
    if (age > 13) {
      formPlayer.value.acceptedOptIn = true;
    } else {
      formPlayer.value.acceptedOptIn = false;
    }
  }
});

watch(() => formPlayer.value.phoneNumber, (newValue, oldValue) => {
  const formatted = formatPhoneNumber(newValue);
  if (newValue !== formatted) {
    formPlayer.value.phoneNumber = formatted;
  }
});
</script>

<style scoped>
.submit-btn {
  background-color: #1d2e48;
  color: white;
  border: none;
}

.border-card-player {
  border: 2px solid #ecf0f5;
  border-top: 13px solid #edfe86;
}
</style>

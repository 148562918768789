<template>
  <section class="w-full max-h-screen">
    <div class="px-7 h-screen pt-7">
      <div class="w-100 flex flex-col justify-center">
        <h1 class="text-white text-lg font-semibold">Players</h1>
      </div>
      <div
        class="bg-white flex flex-wrap xl:grid xl:grid-cols-3 xl:grid-rows-1 gap-2 2xl:gap-14 p-14 mt-7"
      >
        <CardPlayer
          v-for="player in players"
          :key="player.id"
          :player="player"
        ></CardPlayer>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import CardPlayer from "@/components/Players/CardPlayer.vue";
import { getProfiles } from "@/services/user/user";

const players = ref([]);

onMounted(async () => {
  players.value = await getProfiles();
});
</script>

<style>
.submit-btn {
  background-color: #edfe86;
  border: none;
}
</style>

export function formatPhoneNumber(value) {
    const numbers = value.replace(/\D/g, '');
  
    const trimmed = numbers.substring(0, 10);
  
    const areaCode = trimmed.substring(0, 3);
    const middle = trimmed.substring(3, 6);
    const last = trimmed.substring(6, 10);
  
    let formatted = '';
    if (trimmed.length > 6) {
      formatted = `(${areaCode}) ${middle}-${last}`;
    } else if (trimmed.length > 3) {
      formatted = `(${areaCode}) ${middle}`;
    } else if (trimmed.length > 0) {
      formatted = `(${areaCode}`;
    }
    return formatted;
  }
